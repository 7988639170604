import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles.css';
import employeebenefits from '../assets/Benefits/employeebenefits.jpg';
import exercise from '../assets/Benefits/healthInsurance.png';
import retirement from '../assets/Benefits/retirementPlans.jpg';
import vacation from '../assets/Benefits/VacationNew.jpg';
import weekly from '../assets/Benefits/WeeklyPayNew.jpg';
import LifeInsurance from '../assets/Benefits/LifeInsuranceFamily.jpg';
import perks from '../assets/Benefits/perks.jpg';

const Benefits = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update layout dynamically on resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll to element based on hash
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const styles = {
    container: {
      maxWidth: '1000px',
      margin: '0 auto',
      padding: '15px',
    },
    heroSection: {
        position: 'relative',
        overflow: 'hidden',
        width:'100%',
        height: isMobile ? '200px' : '500px', // Adjust height for mobile
      },
      heroImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      },
      heroOverlay: {
        position: 'absolute',
        bottom: isMobile ? '10px' : '20px', // Adjust position for mobile
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent overlay
        color: '#fff',
        padding: isMobile ? '5px 10px' : '30px 40px', // Smaller padding for mobile
        borderRadius: '5px',
        textAlign: 'center',
        fontWeight: 'bold',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
      },
      heroOverlayH1: {
        fontSize: isMobile ? '1rem' : '3rem', // Smaller font size for mobile
        margin: '0',
        lineHeight: '1.5',
      },
  
    valuesContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row', // Column layout for mobile
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '2rem',
      gap:'10px',
    },
    valuesRowContent: {
      flex: isMobile ? 'none' : '1',
      width: isMobile ? '100%' : 'auto', // Full width for mobile
      padding: isMobile ? '10px' : '1rem',
      textAlign: isMobile ? 'center' : 'left',
    },
    imageContainer: {
      flex: isMobile ? 'none' : '1',
      width: isMobile ? '100%' : '100%', // Full width for mobile
      padding: isMobile ? '10px' : '1rem',
      textAlign: 'center',
    },
    benefitImage: {
      maxWidth: isMobile ? '80%' : '100%',
      height: 'auto',
      margin: isMobile ? '0 auto' : 'unset', // Center image for mobile
    },
    list: {
        listStyleType: 'disc', // Use bullets for the list
        margin: '10px auto', // Center-align with some spacing
        paddingLeft: '20px', // Add left padding for alignment
        textAlign: 'left', // Align text to the left
        maxWidth: '90%', // Limit width for better aesthetics
      },
      listItem: {
        fontSize: '1rem', // Adjust font size for readability
        lineHeight: '1.5', // Add space between list items
        marginBottom: '5px', // Add spacing below each item
      },
      
  };

  return (
    <div style={styles.container}>
    <div className="about-us-container">
      <div style={styles.heroSection}>
        <img src={employeebenefits} alt="Our Benefits" style={styles.heroImage} />
        <div style={styles.heroOverlay}>
          <h1 style={styles.heroOverlayH1}>Our Benefits</h1>
        </div>
      </div>
    </div>

      {/* Benefit Sections */}
      <div style={styles.valuesContainer}>
        <div style={styles.imageContainer}>
          <img src={exercise} alt="Health Insurance" style={styles.benefitImage} />
        </div>
        <div style={styles.valuesRowContent}>
          <section id="HealthInsurance">
            <h2>Health Insurance</h2>
            <p>
            Faber is well aware of the high cost of receiving medical attention and treatment. Because the
            Company wants its associates and their dependents to be able to obtain the medical treatment they
            need and deserve, Faber permits all regular full-time associates and regular part-time associates
            with benefits to participate in its health insurance plan.
            </p>
            <ul style={styles.list}>
            <li style={styles.listItem}>Medical Insurance</li>
            <li style={styles.listItem}>Prescription Coverage</li>
            <li style={styles.listItem}>Vision Coverage</li>
            <li style={styles.listItem}>Dental Insurance</li>
            </ul>
          </section>
        </div>
      </div>

      <div style={styles.valuesContainer}>
        <div style={styles.imageContainer}>
          <img src={retirement} alt="Retirement Plans" style={styles.benefitImage} />
        </div>
        <div style={styles.valuesRowContent}>
          <section id="RetirementPlans">
            <h2>Retirement Plans</h2>
            <p>
            To help provide financial security for our associates during their retirement years, Faber maintains
            a 401(k) Savings and Retirement Plan for eligible associates. All associates who are over twenty
            one years of age and have completed 6 months of continuous service with the company are eligible
            to participate as per the 401(k) plan documents.
            </p>
          </section>
        </div>
      </div>

      <div style={styles.valuesContainer}>
        <div style={styles.imageContainer}>
          <img src={LifeInsurance} alt="Life Insurance" style={styles.benefitImage} />
        </div>
        <div style={styles.valuesRowContent}>
          <section id="LifeInsurance">
            <h2>Life Insurance</h2>
            <p>
            Our life insurance benefit provides peace of mind by ensuring financial protection for employees' families. 
            This valuable coverage helps safeguard loved ones in times of need, offering security and support when it matters most.
            </p>
          </section>
        </div>
      </div>

      <div style={styles.valuesContainer}>
        <div style={styles.imageContainer}>
          <img src={vacation} alt="Paid vacation and holidays" style={styles.benefitImage} />
        </div>
        <div style={styles.valuesRowContent}>
          <section id="Vacation">
            <h2>Paid Vacation and Holidays</h2>
            <p>
            We prioritize work-life balance and value our employees' well-being. Our generous vacation and paid holiday policy ensures that our team can recharge, spend quality time with loved ones, and return to work refreshed and motivated.
            </p>
          </section>
        </div>
      </div>

      <div style={styles.valuesContainer}>
        <div style={styles.imageContainer}>
          <img src={perks} alt="Employee Perks" style={styles.benefitImage} />
        </div>
        <div style={styles.valuesRowContent}>
          <section id="Discounts">
            <h2>Employee Perks</h2>
            <p>
            All associates are eligible to receive a discount of 25% on Faber’s merchandise with the following
            exceptions: books, tobacco products, newspapers, phone cards, magazines, Legos, stamps,
            alcoholic beverages and lottery tickets.
            </p>
          </section>
        </div>
      </div>

      <div style={styles.valuesContainer}>
        <div style={styles.imageContainer}>
          <img src={weekly} alt="Weekly Pay" style={styles.benefitImage} />
        </div>
        <div style={styles.valuesRowContent}>
          <section id="Weekly">
            <h2>Weekly Pay</h2>
            <p>
            We care about our employees' financial security, which is why we offer weekly pay. 
            This benefit provides a steady income, giving our team peace of mind and the ability
            to manage their finances more effectively.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
