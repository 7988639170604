import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/Faber-Logo.png'; // Adjust the path as needed
import '../styles.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
      <Link to="/" className="navbar-logo">
        <img src={logo} alt="Faber Logo" />
      </Link>
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>
      <ul className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        <li><Link to="/aboutUs" onClick={closeMenu}>About Us</Link></li>
        <li><Link to="/values" onClick={closeMenu}>Values</Link></li>
        <li><Link to="/gallery" onClick={closeMenu}>Gallery</Link></li>
        <li><Link to="/partners" onClick={closeMenu}>Partners</Link></li>
        <li><Link to="/careers" onClick={closeMenu}>Careers</Link></li>
        <li><Link to="/contactUs" onClick={closeMenu}>Contact Us</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
