import React from 'react';
import '../styles.css'; // Adjust the path as needed

// Import images (replace paths with actual file locations)
import mainImage from '../assets/AboutUs/old.jpg';
import image1848 from '../assets/AboutUs/Attachm1955.jpeg';
import image1955 from '../assets/AboutUs/s-l1600.jpg';
import teamworkImage from '../assets/AboutUs/teamwork.jpg';
import dedicationImage from '../assets/AboutUs/dedication-new.png';
import customerServiceImage from '../assets/AboutUs/Service-customer.jpg';
import commitmentImage from '../assets/AboutUs/commitment.jpg';
import passionImage from '../assets/AboutUs/passion.jpg';
import communityImage from '../assets/AboutUs/community.jpg';
import latestNewsImage from '../assets/AboutUs/award2.jpg';
import recognitionImage from '../assets/AboutUs/award1.jpg';
import present from '../assets/AboutUs/Pic-14.JPG';
import old from '../assets/AboutUs/old.jpg';

function AboutUs() {
  return (
    <div>
    <div className="about-us-page">
    {/* <div className="red-overlay"> */}
    {/* <div className='red-color'> */}
      <div className="about-us-page-section">
        <div className="main-image-container-about">
          <img src={mainImage} alt="Heritage" className="main-image-about" />
        </div>
        <div className="about-us-page-content">
          <h1 className="about-us-page-heading">Travel Redefined</h1>
          <h2 className="about-us-page-subheading">
          Committed to <span className="about-us-page-plus">Excellence</span> Always
          </h2>
          <p>
          We strive to elevate the traveler’s experience by offering curated products
          and exceptional service at every touchpoint. Our goal is to be the trusted
          companion that brings comfort, convenience, and quality to each journey.
</p>
        </div>
      </div>
   

        
        <div className="info-container">
          {/* Timeline Section */}
          <div className="timeline-section">
            <div className="timeline-item">
              <img src={image1955} alt="1848" />
              <p>1848</p>
            </div>
   
            <div className="timeline-item">
              <img src={image1848} alt="1955" />
              <p>1955</p>
            </div>
          </div>

          {/* Pillars Section */}
          <div className="pillars-section">
            <h2>OUR PILLARS OF SUCCESS</h2>
            <div className="pillars-container">
              <div className="pillar">
                <img src={teamworkImage} alt="Teamwork" />
                <p>Teamwork</p>
              </div>
              <div className="pillar">
                <img src={dedicationImage} alt="Dedication" />
                <p>Dedication</p>
              </div>
              <div className="pillar">
                <img src={customerServiceImage} alt="Excellent Customer Service" />
                <p>Excellent Customer Service</p>
              </div>
              <div className="pillar">
                <img src={commitmentImage} alt="Commitment" />
                <p>Commitment</p>
              </div>
              <div className="pillar">
                <img src={passionImage} alt="Passion" />
                <p>Passion</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      
      {/* Evolution Section */}
      {/* Evolution Section */}
<div className="evolution-section">
  <div className="evolution-container">
    <h2 className="evolution-title">OUR EVOLUTION</h2>

    <div className="evolution-timeline">
      {/* Left Evolution Period */}
      <div className="evolution-period">
        <img src={old} alt="1848-1894" />
        <p>1848 - 1894</p>
      </div>

      {/* Dotted Timeline with Text */}
      <div className="timeline-evolution">
        <div className="timeline-line"></div>
        <span className="timeline-label">1894 - 1948</span>
      </div>

      {/* Right Evolution Period */}
      <div className="evolution-period">
        <img src={present} alt="Present" />
        <p>PRESENT</p>
      </div>
    </div>
  </div>
        <div className="evolution-details">
          <div className="evolution-item">
            <img src={communityImage} alt="Community Involvement" />
            <div>
              <h3>COMMUNITY INVOLVEMENT</h3>
              <p>Since 2013, our Book Drive program has donated over $1.5 million worth of books, impacting families across the nation. </p>
                <p>Faber’s support has made it possible for more children to experience the joy of reading and learning.</p>
            </div>
          </div>
          <div className="evolution-item">
            <img src={latestNewsImage} alt="Latest News" />
            <div>
              <h3>LATEST NEWS</h3>
              <p>Punta Gorda Airport’s expansion may feature a Wolfgang Puck restaurant, thanks to our collaboration. The project highlights the ongoing development at the airport.</p>
            </div>
          </div>
          <div className="evolution-item">
            <img src={recognitionImage} alt="Recognition" />
            <div>
              <h3>RECOGNITION</h3>
              <p>Daytona Beach International Airport was named Florida’s 2024 Commercial Airport by the Florida Department of Transportation. Our stores played a key role in adding value to the airport.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
